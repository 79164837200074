@use '../../styles/variables.scss' as *;

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 0;
}

.pagination {
  display: flex;
  gap: 0.5rem;
}

.button {
  padding: 5px 1rem;
  font-size: 1.1rem;
  color: $eggshell-color;
  background-color: $rhythm;
  border: none;
  cursor: pointer;
  transition: 0.2s;
  &:hover {
    filter: brightness(1.09);
  }
  &:active {
    filter: none;
  }
  &:disabled {
    cursor: auto;
    background-color: lightgrey;
    &:hover {
      filter: none;
    }
  }
}

.input {
  width: 50px;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }
}

.pages {
  color: $shadow-blue;
}
