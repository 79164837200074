@use '../../styles/variables' as *;

.wrapper {
  display: flex;
  flex-direction: column;
  font-family: Helvetica;
  border-radius: 0.5rem;
  box-shadow: 0 0 2px $shadow-blue;
  overflow: hidden;
  transition: 0.1s;
  cursor: pointer;
  color: inherit;
  flex-basis: 24%;
  @media (max-width: 1000px) {
    flex-basis: 32%;
  }
  @media (max-width: 768px) {
    flex-basis: 45%;
  }
  @media (max-width: 475px) {
    flex-basis: 85%;
  }
  &:hover {
    box-shadow: 3px 3px 2px $shadow-blue;
  }
}

.picture {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.description {
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.author {
  text-align: right;
}
