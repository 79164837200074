@use '../../styles/variables' as *;

.wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: stretch;
  gap: 10px 1%;
  width: 100%;
  padding: 20px 0;
}
