@use '../../styles/variables.scss' as *;

.wrapper {
  display: flex;
  gap: 1rem;
  justify-content: center;
  margin: 2rem 0;
  * {
    border-radius: 10px;
  }
}

.searchBar {
  width: 250px;
  height: 40px;
  padding: 0 5px;
  font-size: inherit;
  background-color: transparent;
  border: 2px solid $laurel-green;
  &:focus-visible {
    background-color: white;
    border-color: $rhythm;
    outline: none;
  }
}

.searchButton {
  background-color: $laurel-green;
  border: none;
  padding: 0 1rem;
  font-size: 1.1rem;
  color: $rhythm;
  cursor: pointer;
  transition: 0.2s;
  &:hover {
    filter: brightness(1.09);
  }
  &:active {
    filter: none;
  }
}

.select {
  padding: 0 0.5rem;
  font-size: 1rem;
  background-color: inherit;
  border: 2px solid $laurel-green;
  outline: none;
  option {
    background-color: $eggshell-color;
    font-size: 0.9rem;
    font-variant: small-caps;
  }
}
