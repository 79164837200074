.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.photo {
  flex-basis: 70%;
  img {
    display: block;
    width: 100%;
    max-height: 85vh;
    object-fit: cover;
  }
}

.description {
  flex-basis: 30%;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding-right: 0.5rem;
  min-width: 200px;
  @media (max-width: 768px) {
    padding-left: 0.5rem;
  }
}

.author {
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
  padding-top: 0.5rem;
  width: 100%;
  img {
    display: block;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    object-fit: cover;
  }
  a {
    cursor: pointer !important;
  }
}

.small {
  font-size: 0.8rem;
  color: gray;
}

.likes {
  margin-top: auto;
  padding-bottom: 1rem;
}
