@use '../../styles/variables' as *;

.modal {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 100;
  &:hover {
    cursor: pointer;
    * {
      cursor: auto;
    }
  }
}

.content {
  max-width: 85%;
  background-color: $eggshell-color;
  border: 3px solid $opal;
  border-radius: 1rem;
  overflow: hidden;
}
