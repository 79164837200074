@use '../../styles/variables' as *;

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.picture {
  flex-basis: 60%;
  height: 500px;
  background: center / contain url('../../assets/images/not-found.jpg') no-repeat, white;
  border-radius: 20px 0 0 20px;
}

.description {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding-right: 50px;
  height: 500px;
  color: $shadow-blue;
  border-radius: 0 20px 20px 0;
}

.button {
  align-self: flex-end;
  margin-top: 50px;
  width: 80px;
  height: 40px;
  color: white;
  font-weight: bold;
  font-size: 1rem;
  background-color: $shadow-blue;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: .3s;
  &:hover {
    background-color: $rhythm;
  }
}
