@use './variables' as *;

body {
  margin: 0;
  font-size: 16px;
  background-color: $eggshell-color;
}

.container {
  max-width: 1280px;
  padding: 0 20px;
  margin: 0 auto;
}

a {
  text-decoration: none;
}

p {
  margin: 0;
}

ul {
  list-style: none;
}

.info {
  margin: 0 auto;
  padding: 20px;
  text-align: center;
  font-size: 2rem;
  font-weight: bold;
  color: $shadow-blue;
}

.likes {
  display: inline-block;
  width: 16px;
  height: 16px;
  vertical-align: text-top;
  background: center / cover url('../assets/svg/like.svg') no-repeat;
}
