@use '../../styles/variables' as *;

.wrapper {
  padding: 1rem 0;
}

.button {
  margin-top: 40px;
  width: 80px;
  height: 40px;
  color: white;
  font-weight: bold;
  font-size: 1rem;
  background-color: $shadow-blue;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: .3s;
  &:hover {
    background-color: $rhythm;
  }
}
