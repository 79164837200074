@use '../../styles/variables.scss' as *;

.list {
  display: flex;
  margin: 0;
  padding: 0;

  a {
    display: inline-block;
    padding: 1rem;
    font-size: 1.5rem;
    color: $rhythm;
    text-decoration: none;
    border-radius: 0 0 8px 8px;
    transition: 0.2s;
    &.active {
      background-color: $laurel-green;
    }
    &:hover {
      color: $shadow-blue;
      text-decoration: underline;
    }
  }
}

.header {
  justify-content: space-between;
  @media (max-width: 475px) {
    flex-wrap: wrap;
  }
}

.location {
  margin: 0;
  padding: 1rem 0;
  color: $rhythm;
}
