.wrapper {
  display: flex;
  padding: 0.5rem;
  margin-bottom: 1rem;
  gap: 1rem;
  border: 1px solid black;
  border-radius: 1.5rem;
}

.avatar {
  position: relative;
  width: 150px;
  height: 150px;
  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
}

.lock {
  position: absolute;
  bottom: 0;
  right: 10px;
  width: 30px;
  height: 30px;
}

.about {
  p {
    padding: 0.5rem;
  }
}
