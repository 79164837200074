.form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem;
  margin-bottom: 1rem;
  border: 1px solid black;
  border-radius: 1rem;
  gap: 1.5rem;
}

.label {
  position: relative;
}

.error {
  position: absolute;
  display: block;
  font-size: 0.7rem;
  color: red;
  left: 0;
  animation: fromTop 0.2s ease-out forwards;
}

@keyframes fromTop {
  from {
    top: 0;
    opacity: 0;
  }
  to {
    top: 24px;
    opacity: 1;
  }
}

.button {
  width: 20%;
  min-width: 100px;
  padding: 0.5rem;
  border-radius: 0.5rem;
}

.switch {
  --thumb-position: 0%;
  padding: 3px;
  background: lightgray;
  inline-size: 32px;
  block-size: 16px;
  border-radius: 16px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  pointer-events: none;
  touch-action: pan-y;
  border: none;
  outline-offset: 5px;
  box-sizing: content-box;
  display: inline-grid;
  align-items: center;
  grid: [track] 1fr / [track] 1fr;
  cursor: pointer;
  &::before {
    content: '';
    pointer-events: auto;
    grid-area: track;
    inline-size: 16px;
    block-size: 16px;
    background: white;
    border-radius: 50%;
    transform: translateX(var(--thumb-position));
    transition: 0.2s ease-in-out;
  }
  &:checked {
    --thumb-position: calc(32px - 100%);
  }
}
